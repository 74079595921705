const countries = [
	'Australia',
	'Singapore',
	'United States',
	'United Kingdom',
	'New Zealand',
	'Canada',
	'Malaysia',
	'Thailand',
	'Indonesia',
	'Japan'
].sort();

export const countryOptions = countries.map((country) => ({ label: country, value: country }));

/**
 * variants of a social handle
 */
export const variants = {
	disabled: 'disabled',
	registered: 'registered',
	unregistered: 'unregistered'
};

/**
 * gets the variant of the social handle
 * @param {object} socialHandle
 * @returns {string} variant
 */
export const getVariant = (socialHandle) => {
	if (socialHandle['social-network'].toLowerCase() === 'twitter') {
		return variants.disabled;
	}
	return socialHandle.is_registered ? variants.registered : variants.unregistered;
};
