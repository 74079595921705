import cloneDeep from 'lodash.clonedeep';
import { useState } from 'react';
import Collapse from '@mui/material/Collapse';
import Button from '@truescope-web/react/lib/components/form/Button';
import Select from '@truescope-web/react/lib/components/form/Select';
import TextField from '@truescope-web/react/lib/components/form/TextField';
import Content from '@truescope-web/react/lib/components/layout/Content';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { extractError } from '../../components/Api';
import Header from '../../components/Header';
import SocialHandle from './SocialHandle';
import { search } from './api';
import { countryOptions } from './constants';

const SocialHandlesManager = () => {
	const [keyword, setKeyword] = useState('');
	const [location, setLocation] = useState('Australia');
	const [socialHandles, setSocialHandles] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const { showSnackbar } = useSnackbar();
	const disableSearch = isLoading || stringIsNullOrEmpty(keyword) || isNullOrUndefined(location);

	const searchSocialHandles = async () => {
		if (disableSearch) {
			return;
		}
		try {
			setIsLoading(true);
			const data = await search(keyword, location?.value, 'English');
			setSocialHandles(data);
		} catch (e) {
			showSnackbar(extractError(e), snackbarVariants.error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleSocialHandleChange = (socialHandle) => {
		const existingHandleIndex = socialHandles.findIndex(({ url }) => url === socialHandle.url);
		if (existingHandleIndex < 0) {
			showSnackbar(`cannot find social handle ${socialHandle.url} in social handles`);
			setSocialHandles([]);
			return;
		}
		const updatedSocialHandles = [...socialHandles];
		updatedSocialHandles[existingHandleIndex] = cloneDeep(socialHandle);
		setSocialHandles(updatedSocialHandles);
	};

	return (
		<Content size="sm">
			<Grid container spacing={2}>
				<Grid item>
					<Header header="Social Handles" />
				</Grid>
				<Grid item>
					<Grid container>
						<Grid item>
							<TextField
								labelAbove
								label="Search term"
								placeholder="Enter a search term to find social media accounts"
								value={keyword}
								onChange={(_e, value) => setKeyword(value)}
								onEnter={searchSocialHandles}
								disabled={isLoading}
							/>
						</Grid>
						<Grid item>
							<Select
								labelAbove
								label="Location"
								options={countryOptions}
								value={location}
								onChange={(_e, _value, rawValue) => setLocation(rawValue)}
								disabled={isLoading}
							/>
						</Grid>
						<Grid item>
							<Inline horizontalAlignment={horizontalAlignment.right}>
								<Button variant="primary" onClick={searchSocialHandles} disabled={disableSearch} loading={isLoading}>
									Search Handles
								</Button>
							</Inline>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Collapse in={!isLoading && !arrayIsNullOrEmpty(socialHandles)}>
						<Grid container>
							{socialHandles.map((socialHandle) => (
								<Grid item key={socialHandle.url}>
									<SocialHandle socialHandle={socialHandle} onChange={handleSocialHandleChange} />
								</Grid>
							))}
						</Grid>
					</Collapse>
				</Grid>
			</Grid>
		</Content>
	);
};

export default SocialHandlesManager;
