import { isProduction } from '@truescope-web/utils/src/constants';

const searchMetadataApiUrl = isProduction()
	? 'https://search-metadata.prod.truescope.cloud'
	: 'https://search-metadata.dev2.truescope.cloud';

const post = async (apiPath, payload, apiAction) => {
	const response = await fetch(`${searchMetadataApiUrl}/${apiPath}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	});

	if (!response.ok) {
		const msg = `Failed to ${apiAction} - ${response.data}`;
		console.error(msg, response);
		throw new Error(msg);
	}

	const data = await response.json();
	return data;
};

/**
 * searches for handles
 */
export const search = async (keyword, location, language) => {
	const data = await post(
		'checkhandles',
		[
			{
				keyword,
				location,
				language
			}
		],
		'search'
	);
	return data;
};

/**
 * registers handles
 */
export const register = async (handles) => {
	const data = await post('registerhandles', handles, 'register');
	return data;
};

/**
 * degisters handles
 */
export const deregister = async (handles) => {
	const data = await post('registerhandles?deregister=true', handles, 'deregister');
	return data;
};
